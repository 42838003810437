import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import WritingIcon from "assets/icons/WritingIcon.svg";
import placeholderImg from "assets/images/imagePlaceholder.png";
import StyledDataGrid from "components/StyledGrid";
import { memo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function SellerStoreDataGrid() {
  const navigate = useNavigate();
  const loading = useSelector((state) => state.stores?.storeLoading ?? false);
  const store = useSelector((state) => state.stores?.store ?? null);

  const columns = [
    {
      field: "serialNumber",
      headerName: "Sr",
      minWidth: 45,
      flex: 0.3,
      filterable: false,
      renderCell: () => <Typography>1</Typography>,
    },
    {
      field: "image",
      headerName: "Image",
      minWidth: 100,
      flex: 0.4,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <img
            src={params.row?.logo ? params.row?.logo : placeholderImg}
            height={50}
            width={50}
            style={{
              marginTop: 7,
              objectFit: "contain",
            }}
            alt={params.row.brandName}
          />
        </Box>
      ),
    },
    {
      field: "brandName",
      headerName: "Name",
      minWidth: 125,
      flex: 0.6,
      renderCell: (params) => <Typography>{params?.row?.brandName}</Typography>,
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 150,
      flex: 1.25,
      renderCell: (params) => (
        <>
          <Box>
            <Typography textTransform="capitalize">
              {params?.row?.address}
            </Typography>
            <Typography textTransform="capitalize">
              Province: {params?.row?.province}
            </Typography>
            <Typography textTransform="capitalize">
              City: {params?.row?.city}
            </Typography>
            <Typography textTransform="capitalize">
              Area: {params?.row?.area}
            </Typography>
          </Box>
        </>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 30,
      flex: 0.5,
      renderCell: (params) => (
        <Typography>{params?.row?.createdAt.substring(0, 10)}</Typography>
      ),
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      minWidth: 30,
      flex: 0.5,
      renderCell: () => (
        <Box display="flex" sx={actionStyles}>
          <IconButton
            variant="text"
            className="button-styles"
            onClick={() => navigate(`update`)}
          >
            <img src={WritingIcon} alt="edit" className="icon-size" />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <>
      <StyledDataGrid
        rows={[store]}
        columns={columns}
        loading={loading}
        totalPages={1}
      />
    </>
  );
}

export default memo(SellerStoreDataGrid);

const actionStyles = {
  "& .button-styles": { minWidth: "20px", marginLeft: "4px" },
  "& .icon-size": { height: "25px", width: "25px" },
};
