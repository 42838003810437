import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledSwitch from "components/ToggleButton";
import { FastField, FormikProvider, useFormik } from "formik";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addProductVariant,
  setSelectedAttributes,
  updateVariantProduct,
} from "store/slices/productsSlice";
import { CURRENCY } from "utils/constants/constants";
import { getErrorMessage } from "utils/helpers/apiDataHelpers";
import isValidNumber from "utils/helpers/isValidNumber";
import reduceObject from "utils/helpers/reduceObject";
import * as Yup from "yup";
import StyledTextField from "../StyledTextField";
import VariantMediaSection from "./VariantMediaSection";

function VariantForm({ variant }) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.products.productVariantLoading);

  const initialValues = useMemo(
    () => ({
      id: variant?.id ?? "",
      sku: variant?.sku ?? "",
      quantity: variant?.quantity ?? "",
      regularPrice: variant?.regularPrice ?? "",
      onSale: variant?.onSale ?? false,
      salePrice: variant?.salePrice ?? "",
      selectedAttributes: variant?.selectedAttributes ?? [],
      mainImage: variant?.mainImage ?? "",
      gallery: variant?.gallery ?? [],
    }),
    [variant]
  );

  const handleSubmit = useCallback(
    async (values) => {
      const updatedValues = reduceObject(values, initialValues);

      // Early return if no changes
      if (!Object.keys(updatedValues).length) {
        toast.info("No changes have been made");
        return;
      }
      try {
        if (values?.id)
          await dispatch(
            updateVariantProduct({ ...updatedValues, id: values.id })
          );
        else
          await dispatch(
            addProductVariant({
              ...updatedValues,
              selectedAttributes: values.selectedAttributes,
            })
          );
      } catch (error) {
        const message = getErrorMessage(error);
        console.error(message);
      }
    },
    [dispatch, initialValues]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const SalePriceField = useMemo(
    () => (
      <StyledTextField
        fullWidth
        type="number"
        name="salePrice"
        value={formik.values.salePrice}
        onChange={(e) => {
          isValidNumber(e.target.value) &&
            formik.setFieldValue("salePrice", e.target.value);
        }}
        onBlur={() => formik.setFieldTouched("salePrice", true)}
        disabled={!formik.values?.onSale}
        error={Boolean(formik.touched?.salePrice && formik.errors?.salePrice)}
        helperText={
          formik.touched?.salePrice && !!formik.errors?.salePrice
            ? formik.errors.salePrice
            : "Sales price must be lower than the regular price"
        }
        sx={{
          "& .MuiOutlinedInput-input": {
            textAlign: "right",
          },
        }}
        InputProps={{
          startAdornment: (
            <Typography
              color="text.disabled"
              sx={{
                fontSize: "1.25rem",
                borderRight: "1px solid #00000038",
                paddingRight: "1rem",
              }}
            >
              {CURRENCY}
            </Typography>
          ),
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      formik.errors.salePrice,
      formik.touched?.salePrice,
      formik.values.salePrice,
      formik.values.onSale,
      formik.isSubmitting,
    ]
  );

  return (
    <FormikProvider value={formik}>
      <Form
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
          }
        }}
      >
        <Grid container spacing={3}>
          {/* Basic Information Section */}
          <Grid item xs={6}>
            <Stack direction="column" gap={1}>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Product SKU
              </Typography>
              <FastField name="sku">
                {({ field, meta }) => (
                  <StyledTextField
                    {...field}
                    fullWidth
                    type="text"
                    placeholder="Enter Product SKU"
                    error={Boolean(meta.touched && meta.error)}
                    helperText={
                      meta.touched && !!meta.error
                        ? meta.error
                        : "Enter unique Product SKU"
                    }
                  />
                )}
              </FastField>
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack direction="column" gap={1}>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Quantity in Stock
              </Typography>
              <FastField name="quantity">
                {({ field, meta }) => (
                  <StyledTextField
                    {...field}
                    fullWidth
                    type="number"
                    placeholder="0"
                    onChange={(e) => {
                      isValidNumber(e.target.value) && field.onChange(e);
                    }}
                    onInput={(e) => {
                      const value = e.target.value;
                      if (String(value).includes(".")) {
                        e.target.value = meta?.value;
                      } else {
                        e.target.value = value;
                      }
                    }}
                    error={Boolean(meta.touched && meta.error)}
                    helperText={
                      meta.touched && !!meta.error
                        ? meta.error
                        : "This quantity would be ignored if variants are added"
                    }
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        textAlign: "right",
                      },
                    }}
                  />
                )}
              </FastField>
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack direction="column" gap={2}>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Price
              </Typography>
              <FastField name="regularPrice">
                {({ field, meta }) => (
                  <StyledTextField
                    {...field}
                    fullWidth
                    type="number"
                    onChange={(e) => {
                      isValidNumber(e.target.value) && field.onChange(e);
                    }}
                    error={Boolean(meta.touched && meta.error)}
                    helperText={
                      meta.touched && !!meta.error
                        ? meta.error
                        : "Please enter the product price"
                    }
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        textAlign: "right",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <Typography
                          color="text.disabled"
                          sx={{
                            fontSize: "1.25rem",
                            borderRight: "1px solid #00000038",
                            paddingRight: "1rem",
                          }}
                        >
                          {CURRENCY}
                        </Typography>
                      ),
                    }}
                  />
                )}
              </FastField>
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack direction="column" gap={2}>
              <Stack direction="row" justifyContent="space-between">
                <Box>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    fontSize="1.25rem"
                    color="text.secondary"
                  >
                    Sales Price
                  </Typography>
                </Box>
                <FastField name="onSale">
                  {({ field, meta }) => (
                    <StyledSwitch
                      {...field}
                      onChange={field.onChange}
                      checked={meta.value}
                    />
                  )}
                </FastField>
              </Stack>
              {SalePriceField}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <VariantMediaSection />
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" gap={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                onClick={formik.handleSubmit}
                disabled={loading || !formik.dirty}
                sx={{
                  fontWeight: "bold",
                  minWidth: { md: 100, xl: 250 },
                  height: { xs: 50, xl: 55 },
                }}
              >
                {loading
                  ? variant?.id
                    ? "Updating"
                    : "Creating"
                  : variant?.id
                  ? "Update"
                  : "Create"}
              </Button>
              {!variant?.id && (
                <Button
                  variant="outlined"
                  color="primary"
                  disableElevation
                  onClick={() => dispatch(setSelectedAttributes(null))}
                  sx={{
                    fontWeight: "bold",
                    minWidth: { md: 100, xl: 250 },
                    height: { xs: 50, xl: 55 },
                  }}
                >
                  Cancel
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

VariantForm.propTypes = {
  variant: PropTypes.shape({
    id: PropTypes.string,
    sku: PropTypes.string,
    onSale: PropTypes.bool,
    gallery: PropTypes.array,
    mainImage: PropTypes.string,
    selectedAttributes: PropTypes.array.isRequired,
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    salePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    regularPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default memo(VariantForm);

const validationSchema = Yup.object().shape({
  id: Yup.string(),
  selectedAttributes: Yup.array(),
  quantity: Yup.number("Enter a valid number")
    .required("Required*")
    .integer("Must be an integer")
    .min(0, "Quantity must be greater or equal to 0")
    .max(9999, "Quantity is too high"),
  sku: Yup.string()
    .trim()
    .required("Required*")
    .min(3, "SKU is too short")
    .max(18, "SKU is too long"),
  regularPrice: Yup.number("Enter a valid number")
    .required("Required*")
    .moreThan(0, "Price must be greater than 0")
    .max(9999999, "Price is too high"),
  onSale: Yup.boolean(),
  salePrice: Yup.number().when("onSale", {
    is: (value) => value,
    then: Yup.number()
      .required("Required")
      .moreThan(0, "Sale price must be greater than 0")
      .max(999999, "Price is too high")
      .lessThan(
        Yup.ref("regularPrice"),
        "Sale price must be lower than the regular price"
      ),
    otherwise: Yup.number().notRequired(),
  }),
  mainImage: Yup.mixed().required("Required*"),
  gallery: Yup.array()
    .of(Yup.mixed())
    .max(6, "You can add up to 5 images only"),
});
