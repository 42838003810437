import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCategories } from "store/slices/filtersSlice";
import { getProductById, resetProductState } from "store/slices/productsSlice";
import useQueryParams from "utils/hooks/useQueryParams";
import EditProductForm from "./EditProductForm";
import VariantProductForm from "./Variant";

function EditProduct() {
  const params = useParams();
  const query = useQueryParams();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.filters.categories);
  const productLoading = useSelector((state) => state.products.productLoading);
  const product = useSelector((state) => state.products.product);
  const categoriesLoading = useSelector((state) => state.filters.loading);

  useEffect(() => {
    if (!categories.length) {
      dispatch(getCategories());
    }
  }, [categories, dispatch]);

  useEffect(() => {
    const id = setTimeout(() => {
      dispatch(getProductById(params?.id));
    }, 250);

    return () => {
      if (id) {
        clearTimeout(id);
        dispatch(resetProductState());
      }
    };
  }, [dispatch, params?.id]);

  if (
    (!product && (productLoading || categoriesLoading)) ||
    !categories?.length
  )
    return (
      <Box
        height={200}
        my={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );

  if (!product)
    return (
      <Box
        my={2}
        py={2}
        px={1}
        display="flex"
        flexDirection="column"
        gap={2}
        flexGrow={1}
      >
        <CustomBreadcrumbs />
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography color="text.disabled">Product not found</Typography>
        </Box>
      </Box>
    );

  if (product)
    return (
      <Box my={2} pt={2} px={1} display="flex" flexDirection="column" gap={2}>
        <CustomBreadcrumbs />
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
          fontWeight={600}
          sx={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
          }}
        >
          Edit Product {product?.productName ?? ""}
        </Typography>
        {query?.tab === "variants" ? (
          <VariantProductForm />
        ) : (
          <EditProductForm />
        )}
      </Box>
    );
}

EditProduct.displayName = "EditProduct";

export default memo(EditProduct);
