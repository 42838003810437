import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import useQueryParams from "utils/hooks/useQueryParams";

function ProductFormTabs() {
  const query = useQueryParams();
  const navigate = useNavigate();
  const product = useSelector((state) => state.products.product);

  const handleChange = useCallback(
    (_, value) => {
      navigate({
        search: createSearchParams({ tab: value }).toString(),
      });
    },
    [navigate]
  );

  return (
    <Tabs
      aria-label="Product status filters"
      value={query?.tab === "variants" ? "variants" : "main"}
      onChange={handleChange}
      indicatorColor="none"
      sx={{
        "& .MuiTabs-flexContainer": {
          flexWrap: "wrap",
          width: "fit-content",
          borderRadius: 1.5,
          overflow: "hidden",
        },
        "& .MuiTab-root": {
          textTransform: "capitalize",
          bgcolor: "white",
          fontWeight: 600,
          px: "0rem !important",
          py: 1,
        },
        "& .Mui-selected": {
          color: "white !important",
          bgcolor: "primary.main",
        },
        "& .MuiButtonBase-root": {
          minWidth: { md: 100, lg: 175, xl: 200 },
        },
      }}
    >
      <Tab label="Main" value="main" />
      {product?.attributes?.length || product?.variant?.length ? (
        <Tab label="Variants" value="variants" />
      ) : null}
    </Tabs>
  );
}

export default memo(ProductFormTabs);
