import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DataGridSearch from "components/DataGridSearch";
import DataGrid from "components/Videos/DataGrid";
import UpdateFbViews from "components/Videos/UpdateFbViews";
import UploadVideoForm from "components/Videos/UploadVideoForm";
import EditVideoForm from "components/Videos/VideoForm/EditVideoForm";
import VideosFilter from "components/Videos/VideosFilter";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
function Videos({ upload, update }) {
  const userRole = useSelector((state) => state.auth?.userRole);
  if (update) {
    return <EditVideoForm />;
  }
  if (upload) {
    return <UploadVideoForm />;
  }
  return (
    <Box py={2}>
      <Box paddingTop={2} paddingBottom={2} px={1}>
        <CustomBreadcrumbs />
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={600}
        paddingTop={1}
        paddingBottom={2}
      >
        Videos
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        gap={2}
      >
        {userRole === "supplier" && <UpdateFbViews />}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <VideosFilter />
          <DataGridSearch
            searchKeyParam="name"
            searchKeyParamValue="title"
            searchQueryParam="value"
          />
        </Box>
      </Box>
      <Box py={2}>
        <DataGrid />
      </Box>
    </Box>
  );
}
Videos.propTypes = {
  update: PropTypes.bool,
  upload: PropTypes.bool,
};

Videos.defaultProps = {
  update: false,
  upload: false,
};
export default Videos;
