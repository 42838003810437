import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTranslatedData,
  resetLanguageState,
} from "store/slices/languageTranslatorSlice";
import { setLanguage } from "store/slices/uiSlice";

function LanguageToggleButtons(data) {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.UI?.language ?? "En");

  const handleChange = useCallback(
    (_, newSelectedLanguage) => {
      dispatch(setLanguage(newSelectedLanguage));
    },
    [dispatch]
  );

  useEffect(() => {
    if (data?.data) {
      const timeOut = setTimeout(() => {
        dispatch(getTranslatedData(data.data));
      }, 500);
      return () => {
        dispatch(resetLanguageState());
        clearTimeout(timeOut);
      };
    }
  }, [data, dispatch]);

  return (
    <Tabs
      aria-label="Product status filters"
      value={selectedLanguage}
      onChange={handleChange}
      indicatorColor="none"
      sx={{
        "& .MuiTabs-flexContainer": {
          flexWrap: "wrap",
          width: "fit-content",
          borderRadius: 1.5,
          overflow: "hidden",
        },
        "& .MuiTab-root": {
          textTransform: "capitalize",
          bgcolor: "white",
          fontWeight: 600,
        },
        "& .Mui-selected": {
          color: "white",
          bgcolor: "primary.main",
        },
        "& .MuiButtonBase-root": {
          minWidth: "auto",
        },
      }}
    >
      <Tab label="En" value="En" />
      <Tab label="Ar" value="Ar" />
    </Tabs>
  );
}

export default memo(LanguageToggleButtons);
