import CardContent from "@mui/material/CardContent";
import { memo } from "react";
import { useSelector } from "react-redux";
import ProductFormTabs from "../ProductFormTabs";
import StyledMuiCard from "../StyledMuiCard";
import AddVariantSection from "./AddVariantSection";
import ManageVariantsSection from "./ManageVariantsSection";

function VariantProductForm() {
  const variants = useSelector(
    (state) => state.products.product?.variants ?? []
  );
  return (
    <>
      <ProductFormTabs />
      {/* Add Variants */}
      <StyledMuiCard
        title="Add Variants"
        subTitle="Add variant like color, size, etc"
      >
        <CardContent>
          <AddVariantSection />
        </CardContent>
      </StyledMuiCard>

      {/* Manage Variants */}
      {variants.length ? (
        <StyledMuiCard
          title="Manage Variants"
          subTitle="You can view, update or delete variants"
        >
          <CardContent>
            <ManageVariantsSection />
          </CardContent>
        </StyledMuiCard>
      ) : null}
    </>
  );
}

export default memo(VariantProductForm);
