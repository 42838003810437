import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { Form, Formik } from "formik";
import { parsePhoneNumber } from "libphonenumber-js";
import { memo, useCallback, useMemo } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "store/slices/authSlice";
import reduceObject from "utils/helpers/reduceObject";
import * as Yup from "yup";

function GeneralSettings() {
  const dispatch = useDispatch();
  const { fullname, email, phone } = useSelector(
    (state) => state.auth.data.user
  );

  const initialValues = useMemo(
    () => ({
      fullname: fullname ?? "",
      email: email ?? "",
      phone: phone ?? "",
    }),
    [email, fullname, phone]
  );

  const handleSubmit = useCallback(
    (values) => {
      let data = {
        fullname: values.fullname,
        email: values.email,
        phone: values.phone,
      };
      let profileData = {
        fullname,
        email,
        phone,
      };
      let updatedValues = reduceObject(data, profileData);
      dispatch(updateProfile(updatedValues));
    },
    [dispatch, email, fullname, phone]
  );
  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Stack direction="row" gap={3} p={{ xs: 1, md: 1.5 }}>
                <Grid container spacing={3}>
                  <Grid item xs={6} lg={6}>
                    <Stack direction="column" gap={1}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Name
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type="text"
                        {...formik.getFieldProps("fullname")}
                        error={Boolean(
                          formik.touched.fullname && formik.errors.fullname
                        )}
                        helperText={
                          formik.touched.fullname && !!formik.errors.fullname
                            ? formik.errors.fullname
                            : "Enter your name here"
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Stack direction="column" gap={1}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Phone No
                      </Typography>
                      <Box
                        sx={
                          formik.touched.phone && formik.errors.phone
                            ? {
                                "& .react-tel-input .form-control": {
                                  borderColor: "error.main",
                                },
                              }
                            : {}
                        }
                      >
                        <PhoneInput
                          country={"sa"}
                          value={formik.values.phone}
                          onlyCountries={["pk", "sa"]}
                          countryCodeEditable={false}
                          enableSearch={true}
                          onChange={(formattedPhoneNumber) => {
                            let _phoneNumber = formattedPhoneNumber
                              .split(" ")
                              .join("")
                              .replace(/-/g, "");

                            if (_phoneNumber[0] !== "+") {
                              _phoneNumber = "+" + _phoneNumber;
                            }

                            formik.setFieldValue("phone", _phoneNumber);
                          }}
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                          }}
                          placeholder="Phone No"
                          searchPlaceholder="Search country here..."
                          searchStyle={{
                            borderRadius: "5px",
                            padding: "6px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            fontSize: "16px",
                          }}
                          containerStyle={{
                            direction: "ltr",
                          }}
                          inputStyle={{
                            width: "100%",
                          }}
                        />
                        <FormHelperText
                          error={formik.touched.phone && formik.errors.phone}
                        >
                          {formik.touched.phone && formik.errors.phone
                            ? formik.errors.phone
                            : ""}
                        </FormHelperText>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Stack direction="column" gap={1}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Email
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type="email"
                        {...formik.getFieldProps("email")}
                        error={Boolean(
                          formik.touched.email && formik.errors.email
                        )}
                        helperText={
                          formik.touched.email && !!formik.errors.email
                            ? formik.errors.email
                            : "Enter the valid email here"
                        }
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" gap={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={
                    formik.isSubmitting || !formik.isValid || !formik.dirty
                  }
                  onClick={formik.handleSubmit}
                  disableElevation
                  sx={{
                    fontWeight: "bold",
                    minWidth: { md: 100, xl: 250 },
                    height: { xs: 50, xl: 55 },
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={formik.resetForm}
                  sx={{
                    fontWeight: "bold",
                    minWidth: { md: 100, lg: 175, xl: 250 },
                    height: { xs: 50, xl: 55 },
                  }}
                >
                  Reset
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default memo(GeneralSettings);

Yup.addMethod(Yup.string, "phoneNumber", function (message) {
  return this.test(
    "phoneNumber",
    message || "Invalid phone number",
    function (value) {
      if (!value) {
        return true;
      }
      let number = value;
      if (number[0] !== "+") number = "+" + number;
      try {
        const phoneNumber = parsePhoneNumber(number);
        return phoneNumber ? phoneNumber.isValid() : false;
      } catch (error) {
        return false;
      }
    }
  );
});

// Define the validation schema
const validationSchema = Yup.object().shape({
  fullname: Yup.string()
    .trim()
    .required("Required*")
    .min(5, "Name should be at least 5 characters long")
    .max(100, "Name is too long"),
  email: Yup.string()
    .email("Email is invalid")
    .trim()
    .required("Required*")
    .max(80, "Email is too long")
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid Email"),
  phone: Yup.string()
    .trim()
    .required("Required*")
    .phoneNumber("Please enter a valid phone number"),
});
