import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { FastField, useFormikContext } from "formik";
import { memo, useMemo } from "react";
import { CARD_HEADER_BACKGROUND } from "styles/colors";
import { volumeUnits, weightUnits } from "utils/constants/productForm";
import isValidNumber from "utils/helpers/isValidNumber";

function DeliverySection() {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const PackageVolumeUnit = useMemo(
    () => (
      <Autocomplete
        name="packageVolumeUnit"
        options={volumeUnits}
        value={values.packageVolumeUnit}
        onChange={(_, value) => {
          if (value) setFieldValue("packageVolumeUnit", value ?? "");
          else {
            setFieldValue("packageVolumeUnit", "");
            setFieldValue("packageLength", "");
            setFieldValue("packageWidth", "");
            setFieldValue("packageHeight", "");
          }
        }}
        onBlur={() => setFieldTouched("packageVolumeUnit", true)}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            fullWidth
            placeholder="Please Select"
            error={Boolean(
              touched?.packageVolumeUnit && errors?.packageVolumeUnit
            )}
            helperText={
              touched?.packageVolumeUnit && !!errors?.packageVolumeUnit
                ? errors.packageVolumeUnit
                : "Select the volume unit of the package"
            }
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option}>
            {option}
          </li>
        )}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isSubmitting,
      errors.packageVolumeUnit,
      touched?.packageVolumeUnit,
      values.packageVolumeUnit,
    ]
  );

  const PackageLength = useMemo(
    () => (
      <StyledTextField
        fullWidth
        name="packageLength"
        type="number"
        label="Length"
        placeholder="Length"
        value={values.packageLength}
        onChange={(e) => {
          isValidNumber(e.target.value) &&
            setFieldValue("packageLength", e.target.value);
        }}
        onBlur={() => setFieldTouched("packageLength", true)}
        disabled={!values.packageVolumeUnit}
        error={Boolean(touched?.packageLength && errors?.packageLength)}
        helperText={
          touched?.packageLength && !!errors?.packageLength
            ? errors.packageLength
            : "Length should be greater than 0"
        }
        py={2}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isSubmitting,
      values.packageLength,
      values.packageVolumeUnit,
      touched?.packageLength,
      errors.packageLength,
    ]
  );

  const PackageWidth = useMemo(
    () => (
      <StyledTextField
        fullWidth
        name="packageWidth"
        type="number"
        label="Width"
        placeholder="Width"
        value={values.packageWidth}
        onChange={(e) => {
          isValidNumber(e.target.value) &&
            setFieldValue("packageWidth", e.target.value);
        }}
        onBlur={() => setFieldTouched("packageWidth", true)}
        disabled={!values.packageVolumeUnit}
        error={Boolean(touched?.packageWidth && errors?.packageWidth)}
        helperText={
          touched?.packageWidth && !!errors?.packageWidth
            ? errors.packageWidth
            : "Width should be greater than 0"
        }
        py={2}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isSubmitting,
      values.packageWidth,
      values.packageVolumeUnit,
      touched?.packageWidth,
      errors.packageWidth,
    ]
  );

  const PackageHeight = useMemo(
    () => (
      <StyledTextField
        fullWidth
        name="packageHeight"
        type="number"
        label="Height"
        placeholder="Height"
        value={values.packageHeight}
        onChange={(e) => {
          isValidNumber(e.target.value) &&
            setFieldValue("packageHeight", e.target.value);
        }}
        onBlur={() => setFieldTouched("packageHeight", true)}
        disabled={!values.packageVolumeUnit}
        error={Boolean(touched?.packageHeight && errors?.packageHeight)}
        helperText={
          touched?.packageHeight && !!errors?.packageHeight
            ? errors.packageHeight
            : "Height should be greater than 0"
        }
        py={2}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isSubmitting,
      values.packageHeight,
      values.packageVolumeUnit,
      touched?.packageHeight,
      errors.packageHeight,
    ]
  );

  return (
    <StyledMuiCard
      title="Delivery"
      subTitle="Please ensure you have entered the right package weight (kg) and dimensions for accurate shipping fee calculations."
    >
      <CardContent>
        <Stack direction="column" gap={3}>
          {/* Volume Unit */}
          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Package Volume Unit
            </Typography>
            {PackageVolumeUnit}
          </Stack>

          {/* Package Dimensions */}
          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Package Dimensions
            </Typography>
            <Box display="flex" gap={2}>
              {PackageLength}
              {PackageWidth}
              {PackageHeight}
            </Box>
          </Stack>

          {/* Package Weight Section */}
          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Package Weight (kg/g)
            </Typography>
            <FastField name="packageWeight">
              {({ field, meta }) => (
                <StyledTextField
                  {...field}
                  fullWidth
                  type="number"
                  label="Weight"
                  placeholder="Weight"
                  InputProps={{
                    endAdornment: (
                      <FastField name="packageWeightUnit">
                        {({ field }) => (
                          <Select
                            {...field}
                            sx={{
                              "& .MuiSelect-select": {
                                bgcolor: CARD_HEADER_BACKGROUND,
                                color: "text.secondary",
                                fontWeight: 500,
                                fontSize: "1.2rem",
                                minWidth: 40,
                                textAlign: "center",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: 0,
                                boxShadow: "none",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                            }}
                          >
                            {weightUnits.map((option) => (
                              <MenuItem
                                sx={{
                                  fontSize: 16,
                                  textTransform: "none",
                                  textAlign: "center",
                                }}
                                key={option}
                                value={option}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </FastField>
                    ),
                  }}
                  error={Boolean(meta?.touched && meta?.error)}
                  helperText={
                    meta?.touched && !!meta?.error
                      ? meta?.error
                      : "Weight should be greater than 0"
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      pr: 0,
                    },
                  }}
                />
              )}
            </FastField>
          </Stack>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

export default memo(DeliverySection);
