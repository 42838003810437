import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddActions from "components/Vouchers/AddActions";
import DataGrid from "components/Vouchers/DataGrid";
import VoucherForm from "components/Vouchers/VoucherForm";
import VouchersFilters from "components/Vouchers/VouchersFilters";
import VouchersStatusBar from "components/Vouchers/VouchersStatusBar";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import PropTypes from "prop-types";
import { memo } from "react";
import { store } from "store/configureStore";
import { getGroupBuyProduct, setGroupBuy } from "store/slices/groupBuySlice";
function Vouchers({ create, update }) {
  if (create || update) {
    return <VoucherForm />;
  }
  return (
    <Box py={2} px={1}>
      <Box paddingTop={2} paddingBottom={1} px={1}>
        <CustomBreadcrumbs />
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={600}
        paddingTop={1}
      >
        Vouchers
      </Typography>
      <AddActions />
      <Box display="flex" justifyContent="space-between" flexDirection="row">
        <VouchersStatusBar />
        <VouchersFilters />
      </Box>
      <DataGrid />
    </Box>
  );
}

export default memo(Vouchers);
Vouchers.propTypes = {
  create: PropTypes.bool,
  update: PropTypes.bool,
};

Vouchers.defaultProps = {
  create: false,
  update: false,
};

export function loadGroupBuy({ params }) {
  let groupBuy = store
    .getState()
    .groupBuy.results.find((c) => c.id === params.id);
  if (groupBuy) {
    return store.dispatch(setGroupBuy(groupBuy));
  } else {
    return store.dispatch(getGroupBuyProduct(params.id));
  }
}
