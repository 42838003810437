import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import StoreDataGrid from "components/Stores/StoreDataGrid";
import StoreHeaderActions from "components/Stores/StoreHeaderActions";
import StoreFilters from "components/Stores/StoreHeaderActions/StoreFilters";
import UpdateStore from "components/Stores/UpdateStore/UpdateStore";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import PropTypes from "prop-types";
import { memo } from "react";

function Stores({ update }) {
  if (update) {
    return <UpdateStore />;
  }

  return (
    <Box py={2} px={1}>
      <Box paddingTop={2} paddingBottom={2} px={1}>
        <CustomBreadcrumbs />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
          fontWeight={600}
          paddingTop={1}
          paddingBottom={2}
        >
          Stores
        </Typography>
        <StoreFilters />
      </Box>
      <StoreHeaderActions />
      <StoreDataGrid />
    </Box>
  );
}

Stores.propTypes = {
  create: PropTypes.bool,
  update: PropTypes.bool,
};

Stores.defaultProps = {
  update: false,
  create: false,
};

export default memo(Stores);
