import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import { FastField } from "formik";
import { memo } from "react";
import DescriptionEditor from "./DescriptionEditor";

function DescriptionSection() {
  return (
    <StyledMuiCard
      title="Product Details"
      subTitle="Enter product description and specifications. Tip: Product with more details sale more"
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={2}
          p={{ xs: 1, md: 1.5 }}
        >
          <Box py={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Description
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              pt={1}
              fontSize="1rem"
            >
              Enter product details with specifications. Tip: Use bullet points
              for easy viewing
            </Typography>
          </Box>
          <Box>
            <Card>
              <DescriptionEditor />
            </Card>
            <FastField name="description">
              {({ meta }) =>
                meta.touched &&
                meta.error && (
                  <Typography color="error.light" pt={1} fontSize="1rem">
                    {meta.error}
                  </Typography>
                )
              }
            </FastField>
          </Box>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

export default memo(DescriptionSection);
