import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledSwitch from "components/ToggleButton";
import { FastField } from "formik";
import { memo } from "react";
import { CARD_HEADER_BACKGROUND } from "styles/colors";

function ProductActiveStatusSection() {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={2}
      sx={{
        px: { xs: 3, md: 3.5 },
        py: "1rem",
        bgcolor: CARD_HEADER_BACKGROUND,
        borderRadius: 1,
        border: "1px solid #BEBEBE",
        mb: 3,
      }}
    >
      <Stack direction="column" my="1px">
        <Typography variant="h5" color="#5e5873" fontWeight={600}>
          Active Status
        </Typography>
        <Typography variant="body1" fontSize="0.9rem" color="text.secondary">
          Make product active or inactive
        </Typography>
      </Stack>
      <FastField name="active">
        {({ field, meta }) => (
          <StyledSwitch
            name="active"
            onChange={field.onChange}
            checked={meta.value}
          />
        )}
      </FastField>
    </Stack>
  );
}

export default memo(ProductActiveStatusSection);
