import styled from "@emotion/styled";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resetState, userLogin } from "store/slices/authSlice";
import { LINK_TEXT, PRIMARY_LIGHT } from "styles/colors";
import * as Yup from "yup";

const LOCK_TIMES = [5 * 60 * 1000, 30 * 60 * 1000]; // 5 mins and 30 mins in ms
const LOGIN_LOCK_INFO = "loginLockInfo";

export default function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const error = useSelector((state) => state.auth.error);
  const [showPassword, setShowPassword] = useState(false);
  const [lockout, setLockout] = useState({ remainingTime: 0, attempts: 0 });
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(userLogin(values))
      .unwrap()
      .then(() => {
        localStorage.removeItem(LOGIN_LOCK_INFO);
        setLockout({ remainingTime: 0, attempts: 0 });
        navigate("/dashboard");
      })
      .catch(() => {
        const attempts = lockout.attempts + 1;
        const lockDuration =
          attempts > 3 ? LOCK_TIMES[1] : attempts === 3 ? LOCK_TIMES[0] : 0;

        if (lockDuration > 0) {
          const nextAttemptTime = Date.now() + lockDuration;
          localStorage.setItem(
            LOGIN_LOCK_INFO,
            JSON.stringify({ nextAttemptTime, attempts })
          );
          setLockout({ remainingTime: lockDuration, attempts });
        } else {
          setLockout((prev) => ({ ...prev, attempts }));
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    // On mount, load lockout data from localStorage
    const storedData = localStorage.getItem(LOGIN_LOCK_INFO);
    if (storedData) {
      const { nextAttemptTime, attempts } = JSON.parse(storedData);
      const timeRemaining = nextAttemptTime - Date.now();
      if (timeRemaining > 0) {
        setLockout({ remainingTime: timeRemaining, attempts });
      }
    }
  }, []);

  useEffect(() => {
    // Countdown timer
    if (lockout.remainingTime > 0) {
      const timer = setInterval(() => {
        setLockout((prev) => ({
          ...prev,
          remainingTime: prev.remainingTime - 1000,
        }));
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [lockout.remainingTime]);

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  const formatTime = (ms) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return `${minutes}m ${seconds}s`;
  };

  return (
    <Container>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Stack
              direction="column"
              alignContent="center"
              justifyContent="center"
              minHeight="100vh"
              maxWidth={{ sm: "250px", md: "450px", lg: "400px", xl: "535px" }}
              m="auto"
              spacing={{ xs: 2, md: 2.4, lg: 4 }}
            >
              <Typography
                variant="h3"
                component="h2"
                fontSize={{
                  xs: "1.75rem",
                  sm: "2rem",
                  md: "3.25rem",
                  lg: "4rem",
                }}
                lineHeight={1}
              >
                Log In
              </Typography>
              <Typography
                component="h3"
                color="text.secondary"
                fontSize={{
                  xs: "1rem",
                  sm: "1.15rem",
                  md: "1.5rem",
                  lg: "2rem",
                }}
                lineHeight={1}
              >
                Please Login to your account
              </Typography>
              <StyledTextField
                fullWidth
                type="email"
                label="Email Address"
                size={isSmall ? "small" : "large"}
                {...formik.getFieldProps("email")}
                error={Boolean(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Box>
                <StyledTextField
                  fullWidth
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  value={formik.values.password}
                  size={isSmall ? "small" : "large"}
                  {...formik.getFieldProps("password")}
                  error={Boolean(
                    formik.touched.password && formik.errors.password
                  )}
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {error && (
                  <Typography color="error" mt={2}>
                    Incorrect Email or Password
                  </Typography>
                )}

                {lockout.remainingTime > 0 && (
                  <Typography color="error" mt={2}>
                    Too many failed attempts. Try again in{" "}
                    {formatTime(lockout.remainingTime)}.
                  </Typography>
                )}
                <Box mt={1} display="flex" justifyContent="flex-end">
                  <Typography
                    component={Link}
                    to="/recover-password"
                    color={LINK_TEXT}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline !important",
                    }}
                  >
                    Forgot Password
                  </Typography>
                </Box>
              </Box>
              <Button
                variant="contained"
                type="submit"
                size={isSmall ? "small" : "large"}
                sx={{
                  fontWeight: "bold",
                  fontSize: { sm: "0.75rem", md: "1.2rem", xl: "1.25rem" },
                }}
                disabled={
                  !(formik.isValid && formik.dirty) || lockout?.remainingTime
                }
              >
                Log In
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: PRIMARY_LIGHT,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
});

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is invalid")
    .trim()
    .required("*Required")
    .max(80, "Email is too long")
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid Email"),
  password: Yup.string()
    .trim()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(16, "Password must be at most 16 characters"),
});
