import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import _ from "lodash";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import useDebounce from "utils/hooks/useDebounceValue";
import useQueryParams from "utils/hooks/useQueryParams";

function FormSearch({
  formik,
  searchKeyParam,
  searchKeyParamValue,
  searchQueryParam,
}) {
  const params = useQueryParams();
  const navigate = useNavigate();

  const { results, loading } = useSelector((state) => state?.products);
  const [searchValue, setSearchValue] = useState(
    formik.values.productName || ""
  );
  const deferredValue = useDebounce(searchValue, 700);

  const _params = useMemo(() => {
    let _params = { ...params };
    if (deferredValue) {
      if (searchKeyParam) _params[searchKeyParam] = searchKeyParamValue;
      _params[searchQueryParam] = deferredValue;
    } else {
      _params = _.omit(
        params,
        searchKeyParam ? [searchKeyParam, searchQueryParam] : [searchQueryParam]
      );
    }
    return _params;
  }, [
    deferredValue,
    params,
    searchKeyParam,
    searchKeyParamValue,
    searchQueryParam,
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate({
        search: createSearchParams(_params).toString(),
      });
    }, 100);
    return () => clearTimeout(timeout);
  }, [_params, deferredValue, navigate, params]);

  const handleChange = useCallback((value) => {
    setSearchValue(value.trim());
  }, []);

  return (
    <Autocomplete
      options={results.filter((res) => res.active && res.quantity >= 50)}
      value={
        results.find(
          (option) => option.productName === formik.values.productName
        ) || null
      }
      loading={loading}
      inputValue={searchValue}
      onInputChange={(_, value) => handleChange(value)}
      onChange={(_, option) => {
        formik.setFieldValue("productId", option?.id ?? "");
        formik.setFieldValue("productName", option?.productName ?? "");
        formik.setFieldValue("price", option?.price ?? "");
        // formik.setFieldValue("quantity", option?.quantity ?? "");
      }}
      onBlur={formik.handleBlur}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(option) => option?.productName || ""}
      renderInput={(params) => (
        <StyledTextField
          fullWidth
          {...params}
          label="Product name"
          name="productName"
          error={Boolean(formik.touched.productId && formik.errors.productId)}
          helperText={
            formik.touched.productId && !!formik.errors.productId
              ? formik.errors.productId
              : "Products with 50 items are shown."
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.productName}
        </li>
      )}
    />
  );
}

FormSearch.propTypes = {
  formik: PropTypes.object.isRequired,
  searchKeyParam: PropTypes.string,
  searchKeyParamValue: PropTypes.string,
  searchQueryParam: PropTypes.string.isRequired,
};

export default memo(FormSearch);
