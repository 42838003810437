import CancelIcon from "@mui/icons-material/Cancel";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledTextField from "components/authentication/StyledTextField";
import ProductList from "components/Collection/AddCollectionProducts/ProductList";
import UploadCollectionCsv from "components/Collection/AddCollectionProducts/UploadCollectionCsv";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import { Form, Formik } from "formik";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStores } from "store/slices/storesSlice";
import useDebounce from "utils/hooks/useDebounceValue";

function AddCollectionProducts() {
  const dispatch = useDispatch();
  const { loading, results } = useSelector((state) => state?.stores);
  const [value, setValue] = useState("productListUpload");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [store, setStore] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const deferredValue = useDebounce(searchValue, 500);
  const handleChange = useCallback((event) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (newValue === "csvUpload") {
      setIsDialogOpen(true);
    }
  }, []);

  const handleDialogSubmit = () => {
    setIsDialogOpen(false);
  };

  const resetDialogState = () => {
    setIsDialogOpen(false);
  };

  const initialValues = useMemo(
    () => ({
      store: null,
    }),
    []
  );

  const handleStoreChange = useCallback((value) => {
    setSearchValue(value);
  }, []);

  useEffect(() => {
    if (isDialogOpen) {
      const data = {};
      if (deferredValue) {
        data.name = "brandName";
        data.value = deferredValue;
      }
      dispatch(getStores(data));
    }
  }, [deferredValue, dispatch, isDialogOpen]);

  return (
    <Box py={2} px={1}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { md: "1rem", lg: "1.5rem" },
            paddingRight: "1rem",
          }}
        >
          COLLECTIONS
        </Typography>
        <Box paddingTop={2} paddingBottom={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={500}
        mb={1}
      >
        Collection Products
      </Typography>
      <StyledMuiCard
        title="Select Upload Method"
        subTitle="Please select at least one option to upload products"
      >
        <CardContent>
          <Stack
            direction="column"
            justifyContent="center"
            gap={3}
            p={{ xs: 1, md: 1.5 }}
          >
            <FormControl>
              <RadioGroup
                value={value}
                onChange={handleChange}
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="productListUpload"
                  control={<Radio />}
                  label="Select Product"
                />
                <FormControlLabel
                  value="csvUpload"
                  control={<Radio />}
                  label="Upload CSV File"
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        </CardContent>
      </StyledMuiCard>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "1rem",
        }}
      >
        {value === "csvUpload" && (
          <Button variant="contained" onClick={() => setIsDialogOpen(true)}>
            Select Store
          </Button>
        )}
        {value === "csvUpload" && store && (
          <Typography color="PRIMARY">
            Selected Store: {store.brandName}
          </Typography>
        )}
      </Box>

      {value === "csvUpload" ? (
        <UploadCollectionCsv storeId={store.seller} />
      ) : value === "productListUpload" ? (
        <ProductList />
      ) : null}
      <Dialog
        open={isDialogOpen}
        onClose={(reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            resetDialogState();
          }
        }}
        disableEscapeKeyDown
        sx={{
          "& .MuiDialog-paper": { minWidth: { lg: "1000px" } },
        }}
        PaperProps={{
          elevation: 0,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={5}
          bgcolor="primary.main"
          sx={{
            padding: "0.25rem 0 0.25rem 2rem",
          }}
        >
          <Stack direction="column">
            <Typography variant="h5" component="h1" color="white">
              Select Store
            </Typography>
            <Typography variant="body2" color="white">
              Select the store to upload the csv file
            </Typography>
          </Stack>
          <IconButton onClick={resetDialogState} size="large">
            <CancelIcon sx={{ color: "white", fontSize: "2.5rem" }} />
          </IconButton>
        </Stack>
        <Formik
          enableReinitialize={true}
          validateOnBlur={true}
          validateOnChange={true}
          initialValues={initialValues}
          onSubmit={handleDialogSubmit}
        >
          {(formik) => (
            <Form>
              <Stack direction="row" p={4} gap={2}>
                <Box width="100%">
                  <Autocomplete
                    name="store"
                    loading={loading}
                    options={results}
                    value={formik.values.store}
                    noOptionsText="No Options Available"
                    onChange={(_, store) => {
                      setStore(store);
                      formik.setFieldValue("store", store);
                    }}
                    getOptionLabel={(stores) => stores.brandName}
                    isOptionEqualToValue={(option, value) =>
                      option.brandName === value.brandName
                    }
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        autoFocus
                        fullWidth
                        label="Select Store"
                        onChange={(e) => {
                          handleStoreChange(e.target.value);
                        }}
                        error={Boolean(
                          formik.touched.store && formik.errors.store
                        )}
                        helperText={
                          formik.touched.store && !!formik.errors.store
                            ? formik.errors.store
                            : "Select the store to add csv"
                        }
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option.seller}>
                        {option.brandName}
                      </li>
                    )}
                  />
                </Box>
                <Button
                  type="submit"
                  size="large"
                  variant="outlined"
                  sx={{
                    height: "3.5rem",
                    minWidth: 250,
                  }}
                  disabled={!formik.values.store}
                >
                  Continue
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Dialog>
      {store && <ProductList storeId={store.seller} />}
    </Box>
  );
}

export default memo(AddCollectionProducts);
