import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ProductAddImagePlaceholder from "assets/icons/ProductAddImagePlaceholder.svg";
import { useFormikContext } from "formik";
import { memo, useCallback, useRef, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { removeGalleryImage } from "services/Product";
import { getErrorMessage } from "utils/helpers/apiDataHelpers";
import validateImageAspectRatio from "utils/helpers/validateImageAspectRatio";
import GalleryImagePlaceholder from "../GalleryImagePlaceholder";
import ImageCropDialog from "../ImageCropDialog";

function VariantMediaSection() {
  const mainImageRef = useRef(null);
  const galleryRef = useRef(null);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [cropQueue, setCropQueue] = useState([]);
  const [currentCropImage, setCurrentCropImage] = useState(null);
  const [croppedImages, setCroppedImages] = useState([]);
  const [currentCropIndex, setCurrentCropIndex] = useState(0);

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = useFormikContext();

  const handleCropConfirm = useCallback(
    async (croppedBlob, field) => {
      try {
        const croppedFile = new File(
          [croppedBlob],
          currentCropImage?.file?.name,
          {
            type: currentCropImage?.file?.type,
          }
        );
        const isGallery = field === "gallery";
        const isMainImage = field === "mainImage";

        if (cropQueue.length > 1) {
          const _croppedImages = [...croppedImages, croppedFile];

          if (_croppedImages.length < cropQueue.length) {
            setCroppedImages(() => _croppedImages);
            const NextCropImageIndex = currentCropIndex + 1;
            const nextImage = cropQueue[NextCropImageIndex];
            const reader = new FileReader();
            reader.onload = () => {
              setCurrentCropImage(() => ({
                src: reader.result,
                file: nextImage,
                field: "mainImage",
              }));
              setIsCropModalOpen(true);
            };
            reader.readAsDataURL(nextImage);
            setCurrentCropIndex(NextCropImageIndex);
          } else {
            setFieldValue("gallery", [...values.gallery, ..._croppedImages]);
            setCroppedImages(() => []);
            setCropQueue(() => []);
            setCurrentCropImage(() => null);
            setIsCropModalOpen(() => false);
          }
        }

        if (isGallery && cropQueue.length < 1) {
          setFieldValue("gallery", [...values.gallery, croppedFile]);
          setCurrentCropImage(() => null);
          setIsCropModalOpen(() => false);
        }

        if (isMainImage) {
          setFieldValue("mainImage", croppedFile);
          setCurrentCropImage(() => null);
          setIsCropModalOpen(() => false);
        } else {
          mainImageRef.current.value = "";
        }
      } catch (error) {
        console.error(getErrorMessage(error));
      }
    },
    [
      currentCropImage,
      cropQueue,
      croppedImages,
      currentCropIndex,
      setFieldValue,
      values.gallery,
    ]
  );
  const mainImageHandler = useCallback(
    (e) => {
      setFieldTouched("mainImage", true);
      const image = e.target.files[0];
      if (image) {
        // function params
        // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
        validateImageAspectRatio(image, 500, 500, false, true, 1 / 1, "1 / 1")
          .then((img) => {
            setFieldValue("mainImage", img);
            mainImageRef.current.value = "";
          })
          .catch(() => {
            mainImageRef.current.value = "";
            const reader = new FileReader();
            reader.onload = () => {
              setCurrentCropImage(() => ({
                src: reader.result,
                file: image,
                field: "mainImage",
              }));
              setIsCropModalOpen(true);
            };
            reader.readAsDataURL(image);
          });
      }
    },
    [setFieldTouched, setFieldValue]
  );

  const galleryImageHandler = useCallback(
    async (e) => {
      setFieldTouched("gallery", true);
      const selectedFiles = e.target.files;
      const currentGalleryLength = values.gallery?.length ?? 0;

      if (currentGalleryLength + selectedFiles?.length > 6) {
        setFieldError("gallery", "You can upload a maximum of 6 images");
        galleryRef.current.value = "";
        return;
      }
      const validImages = [];
      const invalidImages = [];
      for (const image of selectedFiles) {
        try {
          await validateImageAspectRatio(
            image,
            500,
            500,
            false,
            true,
            1 / 1,
            "1 / 1"
          );
          validImages.push(image);
        } catch {
          invalidImages.push(image);
        }
      }

      if (validImages.length) {
        setFieldValue("gallery", [...values.gallery, ...validImages]);
      }

      if (invalidImages.length === 1) {
        const reader = new FileReader();
        reader.onload = () => {
          setCurrentCropImage(() => ({
            src: reader.result,
            file: invalidImages[0],
            field: "gallery",
          }));
          setIsCropModalOpen(() => true);
        };
        reader.readAsDataURL(invalidImages[0]);
      }

      if (invalidImages.length > 1) {
        setCroppedImages(() => []);
        setCurrentCropIndex(() => 0);
        setCropQueue(() => invalidImages);
        const reader = new FileReader();
        reader.onload = () => {
          setCurrentCropImage({
            src: reader.result,
            file: invalidImages[currentCropIndex],
            field: "gallery",
          });
          setIsCropModalOpen(() => true);
        };

        reader.readAsDataURL(invalidImages[currentCropIndex]);
      }

      galleryRef.current.value = "";
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.gallery]
  );

  const handleRemoveGalleryImage = useCallback(
    (index) => {
      const file = values.gallery[index];
      if (typeof file === "object") {
        const gallery = values.gallery.filter((_, i) => i !== index);
        setFieldValue("gallery", gallery);
      } else {
        removeGalleryImage(values?.id, values.gallery[index])
          .then(() => {
            const gallery = values.gallery.filter((_, i) => i !== index);
            setFieldValue("gallery", gallery);
          })
          .catch((error) => {
            const message = getErrorMessage(error);
            setFieldError("gallery", message);
          })
          .finally(() => {
            galleryRef.current.value = "";
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.gallery, values?.id]
  );

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {/* Product Main Image */}
      <Stack direction="column" gap={2}>
        <Stack direction="row" gap={1} alignItems="center" height="auto">
          <Typography
            variant="h3"
            fontWeight="bold"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Main Image
          </Typography>
          <Typography
            variant="h6"
            fontWeight="bold"
            fontSize="0.85rem"
            color="text.secondary"
          >
            (500*500 pixels)
          </Typography>
        </Stack>
        <Box
          width={{ xs: "150px", md: "350px", xl: "400px" }}
          height={{ xs: "150px", md: "350px", xl: "400px" }}
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={
              values.mainImage
                ? typeof values.mainImage === "string"
                  ? values.mainImage
                  : URL.createObjectURL(values.mainImage)
                : ProductAddImagePlaceholder
            }
            alt="Product Main Media"
            style={{
              ...(!values.mainImage && { cursor: "pointer" }),
              height: "100%",
              width: "100%",
            }}
            onClick={() => !values.mainImage && mainImageRef.current.click()}
          />
          {values.mainImage && (
            <Tooltip title="Update Image">
              <IconButton
                color="primary"
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
                onClick={() => mainImageRef.current.click()}
              >
                <ChangeCircleIcon />
              </IconButton>
            </Tooltip>
          )}
          <input
            type="file"
            ref={mainImageRef}
            style={{ display: "none" }}
            accept="image/x-png,image/png,image/jpeg,image/jpg"
            onChange={mainImageHandler}
          />
        </Box>

        {touched.mainImage && errors.mainImage && (
          <Typography variant="subtitle1" color="error">
            {errors.mainImage}
          </Typography>
        )}
      </Stack>

      {/* Product Gallery Images */}
      <Stack
        direction="column"
        gap={2}
        height="100%"
        justifyContent="space-between"
      >
        <Stack direction="column" gap={2}>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Gallery Images
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              fontSize="0.85rem"
              color="text.secondary"
              lineHeight={1}
            >
              (500*500 pixels)
            </Typography>
          </Stack>
          <Box display="flex" flexDirection="row" gap={2}>
            <input
              multiple
              type="file"
              ref={galleryRef}
              style={{ display: "none" }}
              id="upload-product-gallery-images"
              accept="image/x-png,image/png,image/jpeg,image/jpg"
              onChange={galleryImageHandler}
            />
            {values.gallery?.length < 6 ? (
              <img
                src={ProductAddImagePlaceholder}
                alt="Add Product Gallery Placeholder"
                height="auto"
                width="150px"
                style={{ cursor: "pointer" }}
                onClick={() => galleryRef.current.click()}
              />
            ) : null}
            {!values.gallery?.length
              ? [...Array(5)]?.map((e, i) => (
                  <Box key={i}>
                    <GalleryImagePlaceholder />
                  </Box>
                ))
              : values.gallery?.map((image, index) => (
                  <Stack
                    position="relative"
                    key={index}
                    direction="row"
                    alignItems="flex-start"
                  >
                    <img
                      src={
                        typeof image === "string"
                          ? image
                          : URL.createObjectURL(image)
                      }
                      alt=""
                      height="auto"
                      width="150px"
                    />
                    <Tooltip title="Remove Image">
                      <IconButton
                        onClick={() => handleRemoveGalleryImage(index)}
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                        }}
                      >
                        <RemoveCircle sx={{ color: "#ff0000" }} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                ))}
          </Box>
          {touched?.gallery && errors?.gallery && (
            <Typography variant="subtitle1" color="error">
              {errors?.gallery}
            </Typography>
          )}
        </Stack>
      </Stack>
      <ImageCropDialog
        imgSrc={currentCropImage?.src}
        isOpen={isCropModalOpen}
        onCropConfirm={(croppedBlob) => {
          handleCropConfirm(croppedBlob, currentCropImage?.field);
        }}
      />
    </Box>
  );
}

export default memo(VariantMediaSection);
