import TextField from "@mui/material/TextField";
import styled from "@mui/material/styles/styled";
import { memo } from "react";

const StyledTextField = styled(TextField)({
  "& .MuiFormHelperText-root": {
    fontSize: "0.9rem",
    marginLeft: 0,
  },
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
});
export default memo(StyledTextField);
