import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useRef } from "react";
import isValidNumber from "utils/helpers/isValidNumber";
import http from "utils/httpRequest/http";
function OfferDetailSection({ formik }) {
  const getProductTotalQuantity = useCallback(async (id) => {
    try {
      const response = await http.get(`/products/${id}`);
      const productData = response?.data?.data;
      if (productData?.variants?.length > 0) {
        const totalQuantity = productData?.variants.reduce((total, variant) => {
          return total + (variant.quantity || 0);
        }, 0);
        return totalQuantity;
      } else {
        return productData?.quantity;
      }
    } catch (error) {
      console.error("Error fetching product details:", error);
      return 0;
    }
  }, []);

  const previousProductId = useRef(null);

  const hasSetQuantityToZero = useRef(false);

  useEffect(() => {
    const fetchQuantity = async () => {
      const productId = formik?.values?.productId;
      if (!productId || productId === "") {
        if (!hasSetQuantityToZero.current && formik.values.quantity !== 0) {
          formik.setFieldValue("quantity", 0);
          hasSetQuantityToZero.current = true;
        }
        previousProductId.current = null;
        return;
      }
      hasSetQuantityToZero.current = false;
      if (productId !== previousProductId.current) {
        try {
          const totalQuantity = await getProductTotalQuantity(productId);

          if (formik.values.quantity !== totalQuantity) {
            console.log(`Setting quantity to ${totalQuantity}`);
            formik.setFieldValue("quantity", totalQuantity);
          }
          previousProductId.current = productId;
        } catch (error) {
          console.error("Error fetching total quantity:", error);
          if (formik.values.quantity !== 0) {
            formik.setFieldValue("quantity", 0);
          }
        }
      }
    };

    fetchQuantity();
  }, [formik.values?.productId, getProductTotalQuantity, formik]);

  return (
    <StyledMuiCard
      title="Offer Details"
      subTitle="Add the subscription details."
    >
      <CardContent>
        <Box display="flex" gap={4} py={2.5}>
          <Box display="flex" flexDirection="column" gap={2} flexGrow={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Product quantity
            </Typography>
            <StyledTextField
              name="quantity"
              type="text"
              value={formik.values.quantity}
              disabled={true}
              onChange={(e) => {
                isValidNumber(e.target.value) && formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              placeholder="0"
              py={2}
              error={Boolean(formik.touched.quantity && formik.errors.quantity)}
              helperText={
                formik.touched.quantity && !!formik.errors.quantity
                  ? formik.errors.quantity
                  : "Total items available in this product."
              }
              sx={{
                "& .MuiOutlinedInput-input": {
                  lineHeight: 1,
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "text.secondary",
                  height: "auto",
                  padding: "0.5rem 0.75rem",
                },
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={2} flexGrow={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Limit per user
            </Typography>
            <StyledTextField
              name="limit"
              type="text"
              disabled={true}
              value={formik.values.limit}
              onChange={(e) => {
                isValidNumber(e.target.value) && formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              placeholder="0"
              py={2}
              error={Boolean(formik.touched.limit && formik.errors.limit)}
              helperText={
                formik.touched.limit && !!formik.errors.limit
                  ? formik.errors.limit
                  : "Maximum number of items a user can buy. "
              }
              sx={{
                "& .MuiOutlinedInput-input": {
                  lineHeight: 1,
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "text.secondary",
                  height: "auto",
                  padding: "0.5rem 0.75rem",
                },
              }}
            />
          </Box>
        </Box>
        <Box display="flex" gap={4} py={2.5}>
          <Box display="flex" flexDirection="column" gap={2} flexGrow={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Minimum Product Quantity
            </Typography>
            <StyledTextField
              name="minSubscription"
              type="text"
              value={formik.values.minSubscription}
              disabled={!formik?.values?.productId}
              onChange={(e) => {
                isValidNumber(e.target.value) && formik.handleChange(e);
                if (e.target?.value > 0) {
                  formik.setFieldValue(
                    "limit",
                    Math.ceil(0.01 * e.target.value)
                  );
                } else {
                  formik.setFieldValue("limit", 0);
                }
              }}
              onBlur={formik.handleBlur}
              placeholder="0"
              py={2}
              error={Boolean(
                formik.touched.minSubscription && formik.errors.minSubscription
              )}
              helperText={
                formik.touched.minSubscription &&
                !!formik.errors.minSubscription
                  ? formik.errors.minSubscription
                  : "Minimum quantity required for to be active."
              }
              sx={{
                "& .MuiOutlinedInput-input": {
                  lineHeight: 1,
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "text.secondary",
                  height: "auto",
                  padding: "0.5rem 0.75rem",
                },
              }}
            />
          </Box>

          <Box display="flex" flexDirection="column" gap={2} flexGrow={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Maximum Product Quautity
            </Typography>
            <StyledTextField
              name="maxSubscription"
              type="text"
              value={formik.values.maxSubscription}
              disabled={!formik?.values?.productId}
              onChange={(e) => {
                isValidNumber(e.target.value) && formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              placeholder="0"
              py={2}
              error={Boolean(
                formik.touched.maxSubscription && formik.errors.maxSubscription
              )}
              helperText={
                formik.touched.maxSubscription &&
                !!formik.errors.maxSubscription
                  ? formik.errors.maxSubscription
                  : "Maximum number of products quantity can be sold in deal."
              }
              sx={{
                "& .MuiOutlinedInput-input": {
                  lineHeight: 1,
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "text.secondary",
                  height: "auto",
                  padding: "0.5rem 0.75rem",
                },
              }}
            />
          </Box>
        </Box>
      </CardContent>
    </StyledMuiCard>
  );
}

OfferDetailSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(OfferDetailSection);
