import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedAttributes,
  setVariantIndex,
} from "store/slices/productsSlice";
import { generateAttributeCombinations } from "utils/helpers/productForm";
import VariantForm from "./VariantForm";

function ProductVariants() {
  const dispatch = useDispatch();
  const product = useSelector((state) => state?.products?.product);
  const selectedAttributes = useSelector(
    (state) => state?.products?.selectedAttributes
  );
  const variantIndex = useSelector((state) => state.products.variantIndex);

  const variantOptions = useMemo(() => {
    return generateAttributeCombinations(
      product?.attributes ?? [],
      product?.variants ?? []
    );
  }, [product]);

  const handleChange = useCallback(
    (_, option) => {
      if (!option) dispatch(setSelectedAttributes(null));
      else dispatch(setSelectedAttributes(option));
      variantIndex > -1 && dispatch(setVariantIndex(-1));
    },
    [dispatch, variantIndex]
  );

  const reconstructAttributesArray = useCallback((attributes) => {
    const _attributes = { ...attributes } ?? null;
    delete _attributes?.isDisabled;
    return Object.entries(_attributes ?? [])?.map(([name, value]) => ({
      name,
      value,
    }));
  }, []);

  return (
    <Stack direction="column" gap={3} p={{ xs: 1, md: 1.5 }}>
      <Accordion
        expanded={Boolean(selectedAttributes)}
        TransitionProps={{ unmountOnExit: true }}
        elevation={0}
      >
        <Autocomplete
          name="productOption"
          options={variantOptions}
          value={selectedAttributes}
          onChange={handleChange}
          fullWidth
          sx={{ maxWidth: 460, pl: 2, pb: 2 }}
          getOptionDisabled={(option) => Boolean(option?.isDisabled)}
          getOptionLabel={(option) => {
            const _option = { ...option };
            delete _option.isDisabled;
            return Object.entries(_option)
              .map(([key, value]) => `${key}: ${value}`)
              .join(" ");
          }}
          renderInput={(params) => (
            <StyledTextField
              fullWidth
              {...params}
              label="Select Variant Attributes"
              helperText={"Please select attributes to add variant"}
            />
          )}
          renderOption={(props, option) => {
            const _option = { ...option };
            delete _option.isDisabled;
            return (
              <li {...props} key={JSON.stringify(_option)}>
                <Box display="flex" gap={1}>
                  {Object.entries(_option)?.map(([key, value]) => (
                    <Typography key={key}>
                      {`${key}: `}
                      <span style={{ fontWeight: 600 }}>{value}</span>
                    </Typography>
                  ))}
                </Box>
              </li>
            );
          }}
        />
        <AccordionDetails>
          <VariantForm
            variant={{
              selectedAttributes:
                reconstructAttributesArray(selectedAttributes),
            }}
          />
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}

export default memo(ProductVariants);
