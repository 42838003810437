import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { FastField } from "formik";
import { memo } from "react";
import isValidNumber from "utils/helpers/isValidNumber";

function StockControlSection() {
  return (
    <StyledMuiCard
      title="Stock Control"
      subTitle="Enter the stock of the products"
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={2}
          p={{ xs: 1, md: 1.5 }}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Quantity in Stock
          </Typography>
          <FastField name="quantity">
            {({ field, meta }) => (
              <StyledTextField
                {...field}
                fullWidth
                type="number"
                placeholder="0"
                py={2}
                onChange={(e) => {
                  isValidNumber(e.target.value) && field.onChange(e);
                }}
                onInput={(e) => {
                  const value = e.target.value;
                  if (String(value).includes(".")) {
                    e.target.value = meta?.value;
                  } else {
                    e.target.value = value;
                  }
                }}
                error={Boolean(meta.touched && meta.error)}
                helperText={
                  meta.touched && !!meta.error
                    ? meta.error
                    : "This quantity would be ignored if variants are added"
                }
                sx={{
                  "& .MuiOutlinedInput-input": {
                    lineHeight: 1,
                    fontSize: "2rem",
                    fontWeight: "bold",
                    color: "text.secondary",
                    height: "auto",
                    textAlign: "right",
                  },
                }}
              />
            )}
          </FastField>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

export default memo(StockControlSection);
