import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import styled from "@mui/material/styles/styled";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CurrencyFormatter from "components/CurrencyFormatter";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { quickUpdateProduct } from "store/slices/productsSlice";
import { toast } from "utils/hooks/useToast";

const StyledDel = styled("del")(() => ({}));

export const EditableCell = ({ type, params }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(params.row[type]);
  const dispatch = useDispatch();

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);

    const updateKey =
      type === "price"
        ? params.row.onSale
          ? "salePrice"
          : "regularPrice"
        : type;
    if (type === "price" && params.row.onSale && updateKey === "salePrice") {
      if (parseFloat(value) >= parseFloat(params.row.regularPrice)) {
        toast.error("Sale price must be less than regular price");
        return;
      }
    }
    dispatch(
      quickUpdateProduct({
        productId: params.row.id,
        body: {
          [updateKey]: value,
        },
      })
    );
  };

  useEffect(() => {
    if (!isEditing) {
      if (type === "price") {
        setValue(
          params.row.onSale ? params.row.salePrice : params.row.regularPrice
        );
      } else {
        setValue(params.row[type]);
      }
    }
  }, [
    isEditing,
    type,
    params.row.onSale,
    params.row.regularPrice,
    params.row.salePrice,
    params.row,
  ]);

  return (
    <Box>
      {isEditing ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <TextField
            value={value}
            type="text"
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^[1-9][0-9]*$/.test(inputValue) || inputValue === "") {
                setValue(inputValue);
              }
            }}
            size="small"
            sx={{ maxWidth: 80 }}
          />

          <IconButton onClick={handleSaveClick} sx={{ padding: "3px" }}>
            <CheckCircleOutline color="primary" sx={{ fontSize: "1.2rem" }} />
          </IconButton>
        </Box>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {type === "quantity" ? (
            <Box>
              <Typography>Qty: {value}</Typography>
            </Box>
          ) : params.row.onSale ? (
            <Box>
              <CurrencyFormatter
                className="product-price"
                number={params.row.salePrice}
              />
              <StyledDel
                className="product-price"
                sx={{ color: "error.dark", fontSize: "1rem" }}
              >
                <CurrencyFormatter number={params.row.regularPrice} />
              </StyledDel>
            </Box>
          ) : (
            <CurrencyFormatter number={value} />
          )}
          <IconButton onClick={handleEditClick} sx={{ padding: "3px" }}>
            <EditIcon sx={{ fontSize: "1.2rem" }} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default EditableCell;

EditableCell.propTypes = {
  type: PropTypes.oneOf(["quantity", "price"]).isRequired,
  params: PropTypes.shape({
    row: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      salePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      regularPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      variants: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      onSale: PropTypes.bool,
    }).isRequired,
  }).isRequired,
};
