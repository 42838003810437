import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { useFormikContext } from "formik";
import { memo, useMemo, useState } from "react";
import {
  CATEGORIES,
  LCDSizeOptions,
  RamOptions,
  RomOptions,
  colorOptions,
  fashionSizeOptions,
  footwearSizeOptions,
  heightOrWidthOptions,
  lengthOptions,
  litreOptions,
  mAhOptions,
  mlOptions,
  packOptions,
  piecesOptions,
  productOptions,
  strapOptions,
  waistOptions,
  weightOptions,
} from "utils/constants/productForm";

function ProductOptionsSections() {
  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = useFormikContext();
  const [productOption, setProductOption] = useState(null);
  const selectedCategory = values?.category;

  const productVariantsOptions = useMemo(() => {
    // Extract the parent category
    const parentCategory = selectedCategory?.categoryTree?.[0];
    if (!parentCategory) return [];

    // Filter options using a single category comparison
    return productOptions.filter((option) =>
      option?.categories?.includes(parentCategory)
    );
  }, [selectedCategory]);

  const handleRemoveAttribute = (index) => {
    // Remove errors and shift indices for remaining errors
    if (errors?.attributes?.length) {
      const updatedErrors = [...errors.attributes];
      updatedErrors.splice(index, 1); // Remove the error at the index
      setFieldError("attributes", updatedErrors);
    }

    // Remove touched and shift indices for remaining touched
    if (touched.attributes) {
      const updatedTouched = [...touched.attributes];
      updatedTouched.splice(index, 1); // Remove the touched entry at the index
      setFieldTouched("attributes", updatedTouched);
    }

    // Update the attributes array
    if (values?.attributes?.length) {
      const updatedAttributes = [
        ...values.attributes.slice(0, index),
        ...values.attributes.slice(index + 1),
      ];
      setFieldValue("attributes", updatedAttributes);
    }
  };

  const sizeOptions = useMemo(
    () =>
      selectedCategory?.categoryTree.includes(CATEGORIES.FOOTWEAR)
        ? footwearSizeOptions
        : selectedCategory?.categoryTree.includes(CATEGORIES.FASHION)
        ? fashionSizeOptions
        : selectedCategory?.categoryTree.includes(CATEGORIES.ELECTRONICS) ||
          selectedCategory?.categoryTree.includes(CATEGORIES.HOME_APPLIANCES) ||
          selectedCategory?.categoryTree.includes(
            CATEGORIES.LAPTOPS_AND_COMPUTERS
          )
        ? LCDSizeOptions
        : fashionSizeOptions,
    [selectedCategory]
  );

  const attributes = useMemo(
    () => ({
      Color: colorOptions,
      Size: sizeOptions,
      Waist: waistOptions,
      Pieces: piecesOptions,
      Pack: packOptions,
      Length: lengthOptions,
      Height: heightOrWidthOptions,
      Width: heightOrWidthOptions,
      Litre: litreOptions,
      ML: mlOptions,
      Weight: weightOptions,
      RAM: RamOptions,
      ROM: RomOptions,
      Strap: strapOptions,
      mAh: mAhOptions,
    }),
    [sizeOptions]
  );

  return (
    <StyledMuiCard
      title="Product Options"
      subTitle="Add product options like color, Size, etc"
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={3}
          p={{ xs: 1, md: 1.5 }}
        >
          <Stack direction="row" gap={2}>
            <Autocomplete
              name="productOption"
              options={productVariantsOptions}
              value={productOption}
              onChange={(_, option) => {
                setProductOption(option);
              }}
              fullWidth
              sx={{ maxWidth: 460 }}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <StyledTextField
                  fullWidth
                  {...params}
                  label="Options"
                  helperText="Please select the attribute"
                  sx={{ maxWidth: 460 }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option?.name}>
                  {option?.name}
                </li>
              )}
            />
            <Button
              color="primary"
              variant="outlined"
              disableElevation
              onClick={() => {
                if (
                  values?.attributes?.length &&
                  values?.attributes?.find(
                    (attr) => attr?.name === productOption?.name
                  )
                )
                  return;

                setFieldValue("attributes", [
                  ...values.attributes,
                  { name: productOption?.name, values: [] },
                ]);
              }}
              sx={{
                fontWeight: "bold",
                minWidth: { md: 100, xl: 160 },
                height: { xs: 50, xl: 56 },
              }}
            >
              Add
            </Button>
          </Stack>

          {/* Product Attributes */}
          {values.attributes.map(({ name, values }, index) => {
            const error = errors?.attributes?.[index]?.values;
            const touch = touched?.attributes?.[index]?.values;
            return Object.keys(attributes)?.includes(name) ? (
              <Box key={name}>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  {name}
                </Typography>
                <Stack direction="row" gap={2}>
                  <Autocomplete
                    multiple
                    fullWidth
                    name={name}
                    options={attributes?.[name]}
                    value={values}
                    onChange={(_, options) => {
                      setFieldValue(`attributes[${index}].values`, options);
                    }}
                    onBlur={() => {
                      setFieldTouched(`attributes[${index}].values`, true);
                    }}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        placeholder={name}
                        error={!!touch && !!error}
                        helperText={
                          touch && error
                            ? error
                            : "Please select the appropriate attribute"
                        }
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          label={option}
                          {...getTagProps({ index })}
                          key={option}
                        />
                      ))
                    }
                  />
                  <IconButton
                    color="primary"
                    variant="outlined"
                    sx={{
                      height: { xs: 50, xl: 56 },
                      borderRadius: 0,
                    }}
                    onClick={() => handleRemoveAttribute(index)}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Stack>
              </Box>
            ) : null;
          })}
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

export default memo(ProductOptionsSections);
