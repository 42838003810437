import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import CardTitle from "components/CardTitle";
import DateRange from "components/Pickers/DateRange";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  CartesianGrid,
  Line,
  LineChart as LineChartGraph,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getOrdersChart } from "store/slices/dashboardSlice";
import {
  BUTTON_HOVER,
  BUTTON_HOVER_BORDER_COLOR,
  DASHBOARD_CHART_ROW_COLOR,
  FILTERS_BORDER_COLOR,
  PRIMARY,
} from "styles/colors";
import USER_ROLE from "utils/constants/userRole";
import useQueryParams from "utils/hooks/useQueryParams";
import { toast } from "utils/hooks/useToast";

function LineChart({ isSupplier }) {
  const HEIGHT = isSupplier ? 415 : 450;

  const navigate = useNavigate();
  const params = useQueryParams();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.dashboard.ordersChart);
  const userRole = useSelector((state) => state.auth?.userRole);

  const orders = data?.map((val) => val?.y);
  const totalOrders = orders?.reduce((pre, curr) => pre + curr, 0);

  const processedData = data?.map((item) => {
    const dateObj = new Date(item?.x?.year, item?.x?.month, item?.x?.date);
    const options = { month: "short", day: "numeric" };
    return {
      date: dateObj.toLocaleString("en-US", options),
      value: item?.y,
    };
  });

  const formatTooltipLabel = useCallback((label) => {
    // Parse the date string
    const [month, day] = label.split(" ");
    const year = new Date().getFullYear(); // Assume current year if not provided
    const date = new Date(`${month} ${day}, ${year}`);

    // Format the date as desired (e.g., "Jan 01, 2023")
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
  }, []);

  const handleChange = useCallback(
    (range) => {
      const startDate = range.startDate;
      const endDate = range.endDate;
      const dateIsSame = dayjs(startDate).isSame(endDate, "day");
      if (dateIsSame) {
        toast.error("Start date must be less than end date");
      } else {
        navigate({
          search: createSearchParams({
            ...params,
            from: dayjs(startDate).format("YYYY-MM-DD"),
            to: dayjs(endDate).format("YYYY-MM-DD"),
          }).toString(),
        });
      }
    },
    [navigate, params]
  );

  useEffect(() => {
    if ([USER_ROLE.ADMIN, USER_ROLE.SUPPLIER].includes(userRole)) {
      dispatch(
        getOrdersChart({
          ...(params?.from && { from: params?.from }),
          ...(params?.to && { to: params?.to }),
          // ...(params?.from &&
          //   params?.to && {
          //     format: getDateRangeFormat(params?.from, params?.to),
          //   }),
        })
      );
    }
  }, [dispatch, params?.from, params?.to, userRole]);

  return (
    <Box
      id="dashboard-line-chart"
      p={2}
      height="100%"
      display="flex"
      flexDirection="column"
      position="relative"
    >
      <Box sx={containerStyles} mb={1}>
        <Box>
          <CardTitle title="Orders" color="black" />
          <Typography
            fontSize="1.125rem"
            fontWeight="bold"
            color="#5e5873"
            mb={1}
          >
            {totalOrders ?? 0}
          </Typography>
        </Box>
        {userRole === USER_ROLE.SUPPLIER && (
          <DateRange onChange={handleChange} onClear={handleChange} />
        )}
      </Box>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: HEIGHT,
          }}
        >
          <CircularProgress />
        </Box>
      ) : data?.length ? (
        <Box flex={1}>
          <ResponsiveContainer width="100%" height={HEIGHT}>
            <LineChartGraph
              data={processedData}
              margin={{
                right: 30,
                bottom: 5,
              }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                vertical={false}
                stroke={DASHBOARD_CHART_ROW_COLOR}
              />
              <XAxis
                dataKey="date"
                stroke="#000000"
                tick={{ fill: "#000000" }}
              />
              <YAxis stroke="#000000" tick={{ fill: "#000000" }} />
              <Tooltip
                labelFormatter={formatTooltipLabel}
                contentStyle={{ color: "#000000" }}
              />
              <Line
                type="linear"
                dataKey="value"
                stroke={PRIMARY}
                strokeWidth={4}
                activeDot={{ r: 8 }}
                dot={{
                  strokeWidth: 0,
                  r: 0,
                  strokeColor: PRIMARY,
                  fill: "#fff",
                }}
                name={"Orders"}
              />
            </LineChartGraph>
          </ResponsiveContainer>
        </Box>
      ) : (
        <Box height={HEIGHT} />
      )}
      {!loading && !data.length && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography variant="body2" textAlign="center" color="text.disabled">
            No data found
          </Typography>
        </Box>
      )}
    </Box>
  );
}

LineChart.propTypes = {
  isSupplier: PropTypes.bool,
};

LineChart.defaultProps = {
  isSupplier: false,
};

export default memo(LineChart);

const containerStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .MuiOutlinedInput-root": {
    height: "44px",
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
    },
    "&:hover": {
      backgroundColor: BUTTON_HOVER,
      "& fieldset": {
        borderColor: BUTTON_HOVER_BORDER_COLOR,
      },
    },
  },
};
