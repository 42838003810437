import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/BulkUploadCard/StyledMuiCard";
import PropTypes from "prop-types";
import { memo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bulkUploadProducts } from "store/slices/productsSlice";

function UploadCollectionCsv({ storeId }) {
  const [csvFile, setCsvFile] = useState("");
  const loading = useSelector((state) => state.collection.collectionLoading);

  const dispatch = useDispatch();
  const inputRef = useRef();

  const handleCsvUpload = () => {
    if (csvFile) {
      let formData = new FormData();
      formData.append("csvFile", csvFile);
      dispatch(bulkUploadProducts({ data: formData, storeId }));
    }
  };

  return (
    <Box py={2} px={1}>
      <Box display="flex" maxWidth="100%" justifyContent="space-between">
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
          fontWeight={500}
          mb={1}
        >
          CSV Upload
        </Typography>

        <Link
          underline="hover"
          href="https://bg-video-assets.s3.me-south-1.amazonaws.com/1631193716272.csv"
        >
          Download Template
        </Link>
      </Box>
      <Box py={3}>
        <StyledMuiCard
          title="Upload CSV File"
          subTitle="Upload csv as for the designated required format"
        >
          <Box
            onClick={() => inputRef.current?.click()}
            sx={{
              border: "3px dashed #068ECF",
              padding: "20px",
              margin: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
              cursor: "pointer",
            }}
          >
            {csvFile ? (
              <Typography>{csvFile.name}</Typography>
            ) : (
              <Box display="flex" flexDirection="column" alignItems="center">
                <AddIcon />
                <Typography> Choose File</Typography>
              </Box>
            )}
          </Box>
          <Box textAlign="right" padding="10px">
            <Button
              variant="contained"
              disabled={loading}
              onClick={handleCsvUpload}
              sx={{
                fontWeight: "bold",
                minWidth: { md: 100, xl: 250 },
                height: { xs: 50, xl: 55 },
              }}
            >
              Upload
            </Button>
          </Box>
          <input
            type="file"
            hidden
            ref={inputRef}
            onChange={(e) => setCsvFile(e.target.files[0])}
          />
        </StyledMuiCard>
      </Box>
    </Box>
  );
}

export default memo(UploadCollectionCsv);

UploadCollectionCsv.propTypes = {
  storeId: PropTypes.string.isRequired,
};
