import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addGroupBuyProduct,
  resetErrorState,
  resetGroupBuyState,
  updateGroupBuyProduct,
} from "store/slices/groupBuySlice";
import reduceObject from "utils/helpers/reduceObject";
import * as Yup from "yup";
import OfferDetailSection from "./OfferDetailSection";
import PriceSection from "./PriceSection";
import ProductInformationSection from "./ProductInformationSection";
import TimeDuration from "./TimeDuration";

function GroupBuyForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { groupBuyLoading, groupBuyError, groupBuyProduct } = useSelector(
    (state) => state.groupBuy
  );

  useEffect(() => {
    if (!params?.id) {
      dispatch(resetGroupBuyState());
    }
    dispatch(resetErrorState());
  }, [dispatch, params.id]);

  const initialValues = useMemo(
    () => ({
      id: params.id,
      productId: groupBuyProduct?.productId?.id ?? "",
      productName: groupBuyProduct?.productId?.productName ?? "",
      price: groupBuyProduct?.productId?.regularPrice ?? "",
      groupSalePrice: groupBuyProduct?.groupSalePrice ?? "",
      quantity: "",
      limit: groupBuyProduct?.limit ?? "",
      minSubscription: groupBuyProduct?.minSubscription ?? "",
      maxSubscription: groupBuyProduct?.maxSubscription ?? "",
      startDate: groupBuyProduct?.startDate ?? "",
      endDate: groupBuyProduct?.endDate ?? "",
    }),
    [groupBuyProduct, params]
  );

  const handleSubmit = useCallback(
    (values) => {
      const _data = {
        productId: values.productId,
        limit: +values.limit,
        groupSalePrice: +values.groupSalePrice,
        startDate: values.startDate,
        endDate: values.endDate,
        minSubscription: +values.minSubscription,
        maxSubscription: +values.maxSubscription,
      };
      if (params?.id) {
        let formValues = { ..._data };
        let categoryValues = { ...groupBuyProduct };
        let updatedValues = reduceObject(formValues, categoryValues);
        delete updatedValues["groupSalePrice"];
        delete updatedValues["productId"];

        if (Object.keys(updatedValues).length === 0) {
          return navigate("/products/group-buy");
        }
        dispatch(
          updateGroupBuyProduct({
            _data: updatedValues,
            navigate,
            id: params?.id,
          })
        )
          .unwrap()
          .then(() => navigate("/products/group-buy"));
      } else {
        delete _data["id"];
        dispatch(addGroupBuyProduct({ _data, navigate }))
          .unwrap()
          .then(() => navigate("/products/group-buy"));
      }
    },
    [dispatch, groupBuyProduct, navigate, params?.id]
  );

  return (
    <Box py={2} px={1} sx={textStyle}>
      <Box paddingTop={2} paddingBottom={1} py={1}>
        <CustomBreadcrumbs />
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={500}
        py={1}
        paddingBottom={2}
      >
        {params?.id ? "Update Group Buy" : "Create Group Buy"}
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={7.5}>
                {/* Product Information Section*/}
                <ProductInformationSection formik={formik} />
                {/* Offer Details Section*/}
                <Box mt={2}>
                  <OfferDetailSection formik={formik} />
                </Box>
              </Grid>

              <Grid item xs={12} lg={4.5}>
                {/* Pricing Section */}
                <PriceSection formik={formik} />
                {/* Time Duration Section */}
                <Box mt={2}>
                  <TimeDuration formik={formik} />
                </Box>
              </Grid>
              {/* Action Button */}
              <Grid item xs={12}>
                <Stack direction="row" gap={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={formik.handleSubmit}
                    disabled={groupBuyLoading}
                    sx={{
                      minWidth: { md: 100, xl: 250 },
                      height: { xs: 50, xl: 55 },
                      textTransform: "none",
                      fontSize: {
                        sm: "0.75rem",
                        md: "1.2rem",
                        xl: "1.25rem",
                      },
                    }}
                  >
                    {params?.id
                      ? groupBuyLoading
                        ? "Updating..."
                        : "Update Deal"
                      : groupBuyLoading
                      ? "Creating..."
                      : "Create Deal"}
                  </Button>
                </Stack>
                <Box pt={2} color="error.main">
                  {groupBuyError}
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(GroupBuyForm);
const textStyle = {
  "& .MuiFormHelperText-root": {
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box !important",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    whiteSpace: "normal",
  },
};

const validationSchema = Yup.object().shape({
  productId: Yup.string().required("Please provide a valid product."),
  price: Yup.number("Enter a valid number.")
    .required("Required*")
    .min(1, "Price must be greater than 0.")
    .max(9999999, "Price is too high."),
  groupSalePrice: Yup.number("Enter a valid number.")
    .required("Required*")
    .min(1, "Sale price must be greater than 0.")
    .lessThan(
      Yup.ref("price"),
      "Sale price must be lower than the regular price."
    ),
  quantity: Yup.number("Enter a valid number.")
    .required("Required*")
    .min(1, "Quantity must be greater than 0.")
    .max(1000000, "Quantity is too high."),
  limit: Yup.number("Enter a valid number.")
    .required("Required*")
    .min(
      1,
      "Limit must be greater than 0, please increase the minimum subscription."
    )
    .max(1000000, "Limit is too high."),
  minSubscription: Yup.number("Enter a valid number.")
    .required("Required*")
    .integer("Number should be an integer.")
    .min(1, "Minimum subscription must be greater than 0.")
    .max(1000000, "Minimum subscription is too high.")
    .test(
      "min-less-than-max",
      "Minimum subscription must be less than the maximum subscription.",
      function (value) {
        const { maxSubscription } = this.parent;
        return maxSubscription ? value < maxSubscription : true;
      }
    ),
  maxSubscription: Yup.number()
    .required("Required*")
    .integer("Number should be an integer.")
    .min(1, "Maximum subscription must be greater than 0.")
    .lessThan(
      Yup.ref("quantity"),
      "Maximum subscription should be less than the product quantity"
    )
    .test(
      "max-great  er-than-min",
      "Maximum subscription must be greater than the minimum subscription.",
      function (value) {
        const { minSubscription } = this.parent;
        return minSubscription ? value > minSubscription : true;
      }
    ),
  startDate: Yup.date().when("id", {
    is: (id) => id,
    then: Yup.date().notRequired(),
    otherwise: Yup.date()
      .required("Start Date Required*")
      .min(new Date(), "Start date must be greater than current date."),
  }),
  endDate: Yup.date()
    .required("End date Required*")
    .min(Yup.ref("startDate"), "End date can't be before Start date."),
});
