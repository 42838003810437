import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StoreDescriptionEditor from "components/Stores/UpdateStore/StoreDescriptionEditor";
import PropTypes from "prop-types";
import { memo } from "react";

function StoreDescriptionSection({ formik }) {
  return (
    <StyledMuiCard
      title="Store Details"
      subTitle="Enter description and specifications."
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={2}
          p={{ xs: 1, md: 1.5 }}
        >
          <Box py={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Description
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              pt={1}
              fontSize="1rem"
            >
              Enter store details with specifications. Tip: Use bullet points
              for easy viewing
            </Typography>
          </Box>
          <Box>
            <Card>
              <StoreDescriptionEditor formik={formik} />
            </Card>
            {formik.errors?.description && (
              <Typography color="error.light" pt={1} fontSize="1rem">
                {formik.errors.description}
              </Typography>
            )}
          </Box>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}
StoreDescriptionSection.propTypes = {
  formik: PropTypes.object.isRequired,
};
export default memo(StoreDescriptionSection);
