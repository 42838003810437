import Autocomplete from "@mui/material/Autocomplete";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { FastField, useFormikContext } from "formik";
import { memo } from "react";
import { useSelector } from "react-redux";

function BasicInformationSection() {
  return (
    <StyledMuiCard
      title="Basic Information"
      subTitle="Please enter the basic information of the product such as name and description"
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={3}
          p={{ xs: 1, md: 1.5 }}
        >
          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Product Name
            </Typography>
            <FastField name="name">
              {({ field, meta }) => (
                <StyledTextField
                  {...field}
                  fullWidth
                  type="text"
                  placeholder="Enter Product Name"
                  error={Boolean(meta.touched && meta.error)}
                  helperText={
                    meta.touched && !!meta.error
                      ? meta.error
                      : "Enter a product name to help customer find your product"
                  }
                />
              )}
            </FastField>
          </Stack>

          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Product SKU
            </Typography>
            <FastField name="sku">
              {({ field, meta }) => (
                <StyledTextField
                  {...field}
                  fullWidth
                  type="text"
                  placeholder="Enter Product SKU"
                  error={Boolean(meta.touched && meta.error)}
                  helperText={
                    meta.touched && !!meta.error
                      ? meta.error
                      : "Enter unique Product SKU"
                  }
                />
              )}
            </FastField>
          </Stack>

          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              System Generated SKU
            </Typography>
            <FastField name="system-sku">
              {({ field }) => (
                <StyledTextField {...field} fullWidth type="text" disabled />
              )}
            </FastField>
          </Stack>

          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Product Category
            </Typography>
            <Typography variant="body1" color="text.secondary" fontSize="1rem">
              There are 3 levels categories, select according to the product
            </Typography>
            <CategoriesFastField />
          </Stack>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

export default memo(BasicInformationSection);

const CategoriesFastField = () => {
  const flattenedCategories = useSelector(
    (state) => state?.filters?.flattenedCategories ?? []
  );
  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <FastField name="category">
      {({ meta }) => (
        <Autocomplete
          options={flattenedCategories}
          value={meta.value}
          onChange={(_, category) => {
            setFieldValue("category", category);
          }}
          onBlur={() => setFieldTouched("category", true)}
          getOptionLabel={(option) => option.label}
          getOptionDisabled={(option) => option.categoryTree?.length < 3}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              fullWidth
              label="Category"
              error={Boolean(meta.touched && meta.error)}
              helperText={
                meta.touched && !!meta.error
                  ? meta.error
                  : "Please select a category for the product"
              }
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          )}
        />
      )}
    </FastField>
  );
};
