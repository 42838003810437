import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import AuthPageEllipse from "assets/icons/AuthPageEllipse.svg";
import AuthPageRectangles from "assets/images/AuthPageRectangles.png";
import MySouqLogo from "assets/images/logo.svg";
import AuthSideContent from "components/authentication/AuthSideContent";
import RecoverPasswordForm from "components/authentication/RecoverPasswordForm";

function RecoverPassword() {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));

  return (
    <Grid container component="main" backgroundColor="rgba(6, 142, 207, 0.9)">
      <Grid
        item
        xs={6}
        position="relative"
        sx={{
          backgroundImage: `url("${AuthPageRectangles}")`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <img
          src={MySouqLogo}
          alt="MySouq Logo"
          height="auto"
          width={
            isSmall ? "80px" : isMedium ? "100px" : isLarge ? "125px" : "176px"
          }
          style={{
            position: "absolute",
            top: isMedium ? "1rem" : isLarge ? "1.25rem" : "1.75rem",
            left: isMedium ? "1rem" : isLarge ? "1.25rem" : "1.75rem",
          }}
        />
        <AuthSideContent />
      </Grid>
      <Grid item xs={6} position="relative" overflow="hidden" bgcolor="white">
        <img
          src={AuthPageEllipse}
          alt="Ellipse"
          height={isMedium ? 175 : isLarge ? 225 : 300}
          width={isMedium ? 175 : isLarge ? 225 : 300}
          style={{
            position: "absolute",
            top: isMedium ? -100 : isLarge ? -115 : -150,
            right: isMedium ? -100 : isLarge ? -115 : -150,
          }}
        />
        <RecoverPasswordForm />
      </Grid>
    </Grid>
  );
}

export default RecoverPassword;
