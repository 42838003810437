import Autocomplete from "@mui/material/Autocomplete";
import CardContent from "@mui/material/CardContent";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function MapAeCategorySection({ formik }) {
  const category = useSelector((state) => state.categories?.category);
  const { flattenedCategories } = useSelector(
    (state) => state.categories.aliExpressCategories
  );
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const platformIds = formik.values?.platformIds;
    const filteredCategories = flattenedCategories.filter((category) =>
      platformIds.includes(category.platformId)
    );
    setCategories(filteredCategories);
  }, [flattenedCategories, formik]);

  return (
    <StyledMuiCard
      title="Map Ali Express Category"
      subTitle="Select the category which you want to map to the current category. The selected Ali Express categories products will be sync with the current category products."
    >
      <CardContent>
        <Autocomplete
          name="platformIds"
          multiple={true}
          options={flattenedCategories}
          value={categories}
          onChange={(_, categories) => {
            formik.setFieldValue(
              "platformIds",
              categories.map((category) => category?.platformId)
            );
          }}
          getOptionLabel={(option) => option.label}
          getOptionDisabled={(option) =>
            formik.values?.platformIds?.includes(option.platformId) ||
            Boolean(
              option.mappedWithCategories?.filter(
                (option) => option.id !== category?.id
              )?.length
            )
          }
          onBlur={formik.handleBlur}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => (
            <StyledTextField
              fullWidth
              {...params}
              label="Ali Express Categories"
              error={Boolean(
                formik.touched.platformIds && formik.errors.platformIds
              )}
              helperText={
                formik.touched.platformIds && !!formik.errors.platformIds
                  ? formik.errors.platformIds
                  : "Please select one or more categories that you want to map to the current category"
              }
            />
          )}
          renderOption={({ onClick, ...props }, option) => {
            const mappedCategories = option.mappedWithCategories?.filter(
              (option) => option.id !== category?.id
            );
            return (
              <li {...props} key={option.value} onClick={onClick}>
                {`${option.label}${
                  mappedCategories?.length && mappedCategories?.[0]?.tree
                    ? ` (${mappedCategories?.[0]?.tree})`
                    : ""
                }`}
              </li>
            );
          }}
        />
      </CardContent>
    </StyledMuiCard>
  );
}

MapAeCategorySection.propTypes = {
  formik: PropTypes.any,
};

export default MapAeCategorySection;
